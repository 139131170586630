import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Job } from '../../../../../../services/jobs.service';
import { AosModule } from '../../../../aos/aos.module';

@Component({
    selector: 'app-job-item',
    templateUrl: './job-item.component.html',
    styleUrls: ['./job-item.component.scss'],
    imports: [RouterLink, AosModule],
    standalone: true
})
export class JobItemComponent implements OnInit {
    @Input() job?: Job;
    routeLanguagePrefix = '';

    constructor(private route: ActivatedRoute, private dom: DomSanitizer) {}

    ngOnInit(): void {
        this.routeLanguagePrefix = this.route.snapshot.data['language'] === 'en' ? '/en' : '';
    }
}
