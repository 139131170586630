<div class="col-12 px-4 mt-5 mt-lg-6" appAos>
    <a [routerLink]="routeLanguagePrefix + '/jobs/' + job?.uid">
        <div class="row px-3 py-5 p-5 h-100 shadow" [class.background-pink]="job?.highlight">
            <div class="col-lg-6">
                @if (job?.video) {
                <video class="w-100" controls [poster]="job.thumbnail?.url">
                    <source [src]="'/assets/videos/' + job.video" type="video/mp4" />
                </video>
                } @else {
                <img [src]="job.thumbnail.url" [alt]="job.thumbnail.alt" class="w-100" />
                }
            </div>
            <div class="col mt-5 mt-lg-3 ps-lg-5">
                <p class="heading" [class.color-white]="job?.highlight">
                    {{ job?.city }}
                </p>
                <h3>{{ job?.title }}</h3>
                <p>{{ job?.short_description }}</p>
                @for (tag of job?.tags; track tag) {
                <span class="tag mt-3 me-3" [class.black]="job?.highlight">
                    {{ tag.tag }}
                </span>
                }
            </div>
        </div>
    </a>
</div>
